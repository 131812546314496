export default function AudioLoading() {
  return (
    <div className="input" style={{ borderTop: "2px solid #dddddd" }}>
      <div className="loading-animation">
      <div className="stage" style={{width: "unset", padding: "1rem 0"}}>
            <div className="dot-carousel"></div>
          </div>
      </div>
      <div className="audio-loading-text">Processing Audio</div>
    </div>
  );
}
