import { useEffect, useRef, useState } from "react";

const RecorderTimer = () => {
    const [seconds, setSeconds] = useState(0);
    const interval = useRef(null);
    const formatTime = (totalSeconds) => {
        const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, "0");
        const secs = String(totalSeconds % 60).padStart(2, "0");
        return `${minutes}:${secs}`;
      };

      useEffect(() => {
        interval.current = setInterval(() => {
            setSeconds(prev => (++prev))
        }, 1000);
        return () => {
            clearInterval(interval.current)
        }
      }, [])
    return (
        <div className="center-container text-white">
            {formatTime(seconds)}
        </div>
    )
}
export default RecorderTimer;